import { useQuery } from "@tanstack/react-query"
import { useState, useRef, useMemo, useEffect } from "react"
import {
    SelectCustomStyle,
    IMultiSelectOption,
    IMultiSelectOptionGroup,
    MultiSelectMetaValueContainer,
    MultiSelectOptions,
} from "../MultiSelectFilter"
import { queries } from "../../api/queries"
import { LightbulbIcon } from "../../assets/icons/LightbulbIcon"
import Select, { components } from "react-select"
import _ from "lodash"
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CREATE_INSIGHT_PATH } from "../settings/insights/CreateInsights"
import { Link } from "react-router-dom"
import { useUser } from "../../providers/UserProvider"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"

export interface IInsightFilter {
    insightName: string
    values: string[]
}

export function CustomInsightsFilter(props: {
    onInsightChange: (insight: IInsightFilter | undefined) => void
    selectedInsight: IInsightFilter | undefined
    defaultMenuIsOpen?: boolean
}) {
    const [selectedInsightName, setSelectedInsightName] =
        useState<IMultiSelectOption>()
    const [selectedInsightValues, setSelectedInsightNameValues] = useState<
        IMultiSelectOption[]
    >([])

    const { data: insights, isPending } = useQuery(
        queries.customInsights.list()
    )

    // Set insights state based on selected insight
    useEffect(() => {
        if (insights && props.selectedInsight) {
            const insight = insights.find(
                (insight) => insight.name === props.selectedInsight?.insightName
            )
            if (insight) {
                setSelectedInsightName({
                    value: insight.name,
                    label: insight.display_name || insight.name,
                })

                const values = (
                    insight.options || insight.auto_generated_options?.options
                )
                    ?.filter((option) =>
                        props.selectedInsight?.values.includes(option.id)
                    )
                    .map((option) => ({
                        value: option.id,
                        label: option.name,
                    }))
                setSelectedInsightNameValues(values || [])
            }
        }
    }, [insights, props.selectedInsight])

    const handleInsightNameChange = (
        newValue: IMultiSelectOption | undefined
    ) => {
        setSelectedInsightName(newValue)
        props.onInsightChange(undefined)
    }

    const handleInsightValuesChange = (newValues: IMultiSelectOption[]) => {
        setSelectedInsightNameValues(newValues)

        if (newValues.length === 0) {
            // Clear the whole insight
            setSelectedInsightName(undefined)
            props.onInsightChange(undefined)
            return
        }

        if (selectedInsightName === undefined) return

        props.onInsightChange({
            insightName: selectedInsightName.value,
            values: newValues.map((option) => option.value),
        })
    }

    // Only insights with options (or auto generated options) are available for filtering
    const availableInsights = insights?.filter(
        (insight) =>
            insight.options?.length ||
            insight.auto_generated_options?.options.length
    )

    // Group and sort insights by their group name
    const groupedInsights = Object.fromEntries(
        Object.entries(
            _.groupBy(
                availableInsights,
                (insight) => insight.group_name || " General" // With " " prefix so General group is at the top
            )
        ).sort(([a], [b]) => a.localeCompare(b))
    )

    // Map insights to options for the first <Select />
    const insightNameOptions: IMultiSelectOptionGroup[] = Object.entries(
        groupedInsights
    ).map(([groupName, insights]) => ({
        label: groupName,
        options: insights.map((insight) => ({
            value: insight.name,
            label: insight.display_name || insight.name,
        })),
    }))

    // Map the selected insight's options to options for the second <MultiSelect />
    const valueOptions = useMemo(() => {
        if (selectedInsightName === undefined) return []
        const insight = availableInsights?.find(
            (insight) => insight.name === selectedInsightName?.value
        )
        const options =
            insight?.options || insight?.auto_generated_options?.options || []

        return options.map((option) => ({
            value: option.id,
            label: option.name,
        }))
    }, [selectedInsightName, availableInsights])

    const icon = <LightbulbIcon className="w-5 h-5 text-gray-500" />

    return (
        <>
            {selectedInsightName === undefined ? (
                // First <Select /> to select the insight name
                <SelectFilter
                    title="Insights"
                    icon={icon}
                    options={insightNameOptions}
                    value={selectedInsightName}
                    onApply={handleInsightNameChange}
                    isLoading={isPending}
                    defaultMenuIsOpen={props.defaultMenuIsOpen}
                />
            ) : (
                // Second <MultiSelect /> to select the values for that insight
                <MultiSelectFilter
                    title={selectedInsightName.label}
                    icon={icon}
                    options={valueOptions}
                    value={selectedInsightValues}
                    onApply={handleInsightValuesChange}
                    isLoading={isPending}
                    defaultMenuIsOpen={props.defaultMenuIsOpen}
                />
            )}
        </>
    )
}

function SelectFilter(props: {
    title: string
    icon: React.ReactNode
    options: IMultiSelectOptionGroup[]
    value: IMultiSelectOption | undefined
    onApply: (selectedOption: IMultiSelectOption | undefined) => void
    isLoading: boolean
    defaultMenuIsOpen?: boolean
}) {
    const noInsightsAvailable = !props.isLoading && props.options.length === 0
    return (
        <div
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                noInsightsAvailable
                    ? "No supported insights available"
                    : undefined
            }
        >
            <Select
                options={props.options}
                onChange={(newValue) =>
                    props.onApply(newValue as IMultiSelectOption)
                }
                value={props.value}
                isLoading={props.isLoading}
                isDisabled={noInsightsAvailable}
                placeholder="Insight"
                className="w-64 text-base"
                styles={SelectCustomStyle}
                components={{
                    ValueContainer: InsightContainer,
                    Menu: SelectFilterMenu,
                }}
                // @ts-ignore
                // Custom props
                icon={props.icon}
                title={props.title}
                isClearable={false}
                defaultMenuIsOpen={props.defaultMenuIsOpen ?? true}
            />
        </div>
    )
}

function SelectFilterMenu(props: any) {
    const user = useUser()
    const canEditCustomInsights =
        user && hasPermission(user, Permission.EDIT_CUSTOM_INSIGHTS)

    return (
        <components.Menu {...props}>
            <div className="flex p-3 font-semibold text-sm justify-between items-center">
                Insights
                {canEditCustomInsights ? (
                    <Link to={CREATE_INSIGHT_PATH}>
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="text-gray-600 hover:text-gray-800 w-4 h-4"
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content="Create a new insight"
                        />
                    </Link>
                ) : (
                    <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="text-gray-600 opacity-50 w-4 h-4"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content="You need to be an admin to create a new insight"
                    />
                )}
            </div>
            {props.children}
        </components.Menu>
    )
}

function MultiSelectFilter(props: {
    title: string
    icon: React.ReactNode
    options: IMultiSelectOption[]
    value: IMultiSelectOption[]
    onApply: (selectedOption: IMultiSelectOption[]) => void
    isLoading: boolean
    defaultMenuIsOpen?: boolean
}) {
    const selectRef = useRef<any>(null)

    useEffect(() => {
        // Focus the select when the component mounts so that clicking outside the dropdown closes it
        selectRef.current?.focus()
    }, [])

    return (
        <Select
            ref={selectRef}
            options={props.options}
            defaultMenuIsOpen={props.defaultMenuIsOpen ?? true}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={(newValue) =>
                props.onApply(newValue as IMultiSelectOption[])
            }
            value={props.value}
            isLoading={props.isLoading}
            className="min-w-[256px] w-auto text-base"
            styles={SelectCustomStyle}
            components={{
                ValueContainer: InsightsValueContainer,
                Option: MultiSelectOptions,
            }}
            // @ts-ignore
            // Custom props
            icon={props.icon}
            title={props.title}
            isClearable={false}
        />
    )
}
const InsightContainer = ({ children, ...rest }: any) => {
    const { title, icon } = rest.selectProps

    return (
        <MultiSelectMetaValueContainer
            valueContainerChildren={children}
            {...rest}
        >
            <div className="flex items-center gap-2 text-gray-500">
                {icon}
                <span>{title}</span>
            </div>
        </MultiSelectMetaValueContainer>
    )
}

const InsightsValueContainer = ({ children, ...rest }: any) => {
    const { getValue, hasValue } = rest
    const { title, icon } = rest.selectProps
    const numSelected = getValue().length

    const [selectedValues] = children
    return (
        <MultiSelectMetaValueContainer
            valueContainerChildren={children}
            {...rest}
        >
            <div className="flex items-center gap-2">
                {icon}
                <span className="bg-neutral-200 px-2 h-8 inline-flex items-center rounded-sm">
                    {title}
                </span>
                <span>
                    {numSelected === 0 && "is..."}
                    {numSelected === 1 && "is"}
                    {numSelected > 1 && "is any of"}
                </span>
                {hasValue && selectedValues}
            </div>
        </MultiSelectMetaValueContainer>
    )
}
