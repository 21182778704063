import { ReactElement, ReactNode, useEffect, useState } from "react"
import clsx from "clsx"

interface EditableCellProps<T> {
    value: T | null
    onChange: (newValue: T) => Promise<any>
    isEditable: boolean
    renderDisplay: (value: T | null) => ReactNode
    renderEdit: (
        value: T | null,
        onChange: (newValue: T) => void,
        onBlur: () => void
    ) => ReactElement
    validate?: (newValue: T) => boolean
}

export function EditableCell<T>({
    value,
    onChange,
    isEditable,
    renderDisplay,
    renderEdit,
    validate = () => true,
}: EditableCellProps<T>) {
    const [isEditing, setIsEditing] = useState(false)
    const [editValue, setEditValue] = useState<T | null>(value)
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        setEditValue(value)
    }, [value])

    const handleBlur = async () => {
        setIsEditing(false)
        if (editValue !== null && validate(editValue) && editValue !== value) {
            try {
                setIsUpdating(true)
                await onChange(editValue)
            } finally {
                setIsUpdating(false)
            }
        }
    }

    if (isUpdating) {
        return (
            <div
                className="h-8 w-full animate-pulse bg-gray-200 rounded-lg p-1"
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Updating..."
            />
        )
    }

    if (isEditable && isEditing) {
        return renderEdit(editValue, setEditValue, handleBlur)
    }

    return (
        <div
            className={clsx(
                "w-full border-gray-400 rounded-lg",
                isEditable &&
                    "cursor-pointer border border-opacity-0 hover:border-opacity-100 hover:bg-white hover:cursor-text"
            )}
            onClick={() => {
                if (isEditable) {
                    setIsEditing(true)
                }
            }}
        >
            {renderDisplay(value)}
        </div>
    )
}
