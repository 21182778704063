import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useMemo, useState } from "react"
import { HashLink } from "react-router-hash-link"
import { useNotification } from "../../providers/NotificationProvider"
import { ICRMDeal, ICrmDealUpdate } from "../../types/Deal"
import { SecondaryButton } from "../common/Buttons"
import { LogoLink } from "../common/LogoLink"
import { NotificationType } from "../common/Notifcations"
import { SimpleCard } from "../common/SimpleCard"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { useUser } from "../../providers/UserProvider"
import { Permission } from "../../types/Permission"
import { hasPermission } from "../../utils/Permissions"
import {
    EditableAmountCell,
    EditableCloseDateCell,
    EditableDealStageCell,
} from "../deals/editable-cells"
import { ICrmDealStage } from "../crm/types/Crm"
import { FrigadeDealEditingTour } from "../Frigade"
import { useUpdateDeal } from "../../api/mutations"

const maxDealsDisplayed = 3

export function CRMDeals(props: { companyDomain: string }) {
    const { addNotification } = useNotification()
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

    const {
        data: deals,
        isPending,
        error,
    } = useQuery(queries.companies.dealsFromCrm(props.companyDomain))

    const {
        data: dealStages,
        isPending: dealStagesLoading,
        error: dealStagesError,
    } = useQuery(queries.crm.dealStages())

    const updateDealMutation = useUpdateDeal(
        queries.companies.dealsFromCrm(props.companyDomain).queryKey
    )

    const needsCollapseState = deals && deals.length > maxDealsDisplayed
    const dealsToDisplay = useMemo(() => {
        return isCollapsed ? deals?.slice(0, maxDealsDisplayed) : deals
    }, [isCollapsed, deals])

    if (error) {
        if (axios.isAxiosError(error) && error.response?.status === 412) {
            return <MissingCRMIntegration />
        } else {
            addNotification(
                "Failed to load company deals",
                "",
                NotificationType.Error
            )
            return null
        }
    }

    if (dealStagesError) {
        addNotification(
            "Failed to load deal stages",
            "",
            NotificationType.Error
        )
        return null
    }

    if (isPending || dealStagesLoading) {
        return <LoadingDeal />
    }

    if (!deals?.length) {
        return <MissingDeal />
    }

    return (
        <div className="w-full flex flex-col gap-2">
            <div className="flex flex-col gap-2">
                {dealsToDisplay?.map((deal, index) => (
                    <Deal
                        key={deal.id}
                        deal={deal}
                        dealStages={dealStages || []}
                        updateDeal={async (update: ICrmDealUpdate) =>
                            await updateDealMutation.mutateAsync(update)
                        }
                        canShowFrigadeFlow={index === 0}
                    />
                ))}
            </div>
            {needsCollapseState && (
                <div className="flex justify-center font-semibold text-sm">
                    <button
                        className="p-1 place-self-end text-gray-800 text-sm font-semibold leading-tight tracking-tight outline-none"
                        onClick={() => setIsCollapsed((prev) => !prev)}
                    >
                        {isCollapsed ? "View more deals" : "View fewer deals"}{" "}
                        {isCollapsed ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronUp} />
                        )}
                    </button>
                </div>
            )}
        </div>
    )
}

function MissingCRMIntegration() {
    return (
        <SimpleCard className="min-h-[70px]">
            <span className="flex p-2 space-x-6 items-center">
                <span className="w-1/2 text-base text-gray-600">
                    Connect Glyphic to your CRM to see deals with this company
                </span>
                <HashLink smooth to="/settings#crm">
                    <SecondaryButton>Settings</SecondaryButton>
                </HashLink>
            </span>
        </SimpleCard>
    )
}

function LoadingDeal() {
    return (
        <SimpleCard className="min-h-[70px]">
            <div className="flex flex-row justify-between w-full">
                <div className="flex animate-pulse">
                    <CRMDealAttribute
                        label="Deal"
                        value="Loading deal from CRM"
                    />
                </div>
            </div>
        </SimpleCard>
    )
}

function MissingDeal() {
    return (
        <SimpleCard className="min-h-[70px]">
            <div className="flex flex-row justify-between w-full">
                <div className="flex">
                    <CRMDealAttribute label="Deal" value="No CRM Deal yet" />
                </div>
            </div>
        </SimpleCard>
    )
}

function Deal(props: {
    deal: ICRMDeal
    dealStages: ICrmDealStage[]
    updateDeal: (update: ICrmDealUpdate) => Promise<any>
    canShowFrigadeFlow?: boolean
}) {
    const deal = props.deal
    const user = useUser()
    const canEditDeals =
        (user && hasPermission(user, Permission.EDIT_CRM_DEALS)) || false

    return (
        <SimpleCard className="min-h-[70px]">
            <div className="flex flex-row justify-between w-full">
                <div className="grid md:grid-flow-col grid-cols-2 md:grid-cols-none md:divide-x divide-gray-300 gap-1 w-full">
                    <CRMDealAttribute label="Deal" value={deal.name} />
                    <div className="py-2 px-5 space-y-1">
                        <div
                            id="tooltip-crm-deal-edit"
                            className="text-gray-900 text-xs font-semibold leading-none tracking-tight"
                        >
                            Stage
                        </div>
                        {props.canShowFrigadeFlow && canEditDeals && (
                            <FrigadeDealEditingTour />
                        )}
                        <EditableDealStageCell
                            value={deal.stage}
                            dealStages={props.dealStages}
                            isEditable={canEditDeals}
                            onChange={async (newStage) => {
                                await props.updateDeal({
                                    id: deal.id,
                                    stage: newStage,
                                })
                            }}
                        />
                    </div>
                    <div className="py-2 px-5 space-y-1">
                        <div className="text-gray-900 text-xs font-semibold leading-none tracking-tight">
                            Close Date
                        </div>
                        <EditableCloseDateCell
                            value={deal.close_date}
                            isEditable={canEditDeals}
                            onChange={async (newDate) => {
                                await props.updateDeal({
                                    id: deal.id,
                                    close_date: newDate,
                                })
                            }}
                        />
                    </div>
                    <CRMDealAttribute label="Owner" value={deal.owner?.name} />
                    <div className="py-2 px-5 space-y-1">
                        <div className="text-gray-900 text-xs font-semibold leading-none tracking-tight">
                            Amount
                        </div>
                        <EditableAmountCell
                            value={deal.amount}
                            isEditable={canEditDeals}
                            onChange={async (newAmount) => {
                                await props.updateDeal({
                                    id: deal.id,
                                    amount: newAmount,
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="p-1 md:p-0">
                    <CRMLogo url={deal.crm_url} />
                </div>
            </div>
        </SimpleCard>
    )
}

function CRMLogo({ url }: { url: string | null }) {
    if (!url) return null

    return (
        <LogoLink
            url={url}
            logo={
                <FontAwesomeIcon
                    className="px-2"
                    icon={crmIconFromUrl(url)}
                    size="lg"
                />
            }
        />
    )
}

function CRMDealAttribute(props: {
    label: string
    value: string | null | undefined
}) {
    return (
        <div className="py-2 px-5 space-y-1">
            <div className="text-gray-900 text-xs font-semibold leading-none tracking-tight">
                {props.label}
            </div>
            <div className="text-gray-900 text-xs md:text-base font-normal leading-normal">
                {props.value || "Unspecified"}
            </div>
        </div>
    )
}
