import { EditableCell } from "./EditableCell"
import { BaseEditableCellProps } from "./types"
import {
    convertDateToISODateString,
    getSimpleDateWithoutYear,
} from "../../../utils/datetime"

type EditableCloseDateCellProps = BaseEditableCellProps<string>

export function EditableCloseDateCell({
    value,
    onChange,
    isEditable,
}: EditableCloseDateCellProps) {
    return (
        <EditableCell
            value={value}
            onChange={(newDate) =>
                onChange(convertDateToISODateString(newDate))
            }
            isEditable={isEditable}
            renderDisplay={(val: string | null) => (
                <div className="w-24">
                    {val ? getSimpleDateWithoutYear(val) : "--"}
                </div>
            )}
            renderEdit={(val, setVal, onBlur) => (
                <input
                    type="date"
                    value={val ? new Date(val).toISOString().split("T")[0] : ""}
                    onChange={(e) => setVal(e.target.value)}
                    onBlur={onBlur}
                    onKeyDown={(e) => e.key === "Enter" && onBlur()}
                    className="w-28 border-gray-400 rounded-lg"
                    autoFocus
                />
            )}
            validate={(val) => val !== null && !isNaN(new Date(val).getTime())}
        />
    )
}
