import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { getFormattedDateTime, getSimpleDate } from "../utils/datetime"
import { useCallback, useEffect, useRef, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useDebounce } from "@uidotdev/usehooks"
import { Modal } from "./common/Modal"
import clsx from "clsx"
import {
    ICallSearchResult,
    ICompanySearchResult,
    ISearchResult,
    ISearchResultHighlight,
    ISearchResultHighlightElement,
    SearchResultType,
} from "../types/search"
import { getHeapInstance } from "../utils/heap"
import { CompanyLogo } from "./company-view/CompanyLogo"
import { RecordingIcon } from "../assets/icons/RecordingIcon"
import { SearchEmptyState } from "./SearchEmptyState"
import { Message, Thread } from "./question-answering/types/ThreadTypes"
import { AskGlyphicThread } from "./question-answering/AskGlyphicThread"
import { PriorityButton } from "./common/Buttons"
import { useFeedbackApi } from "./question-answering/hooks/useFeedbackApi"
import { sendFollowUpEmail } from "../utils/createEmailLink"
import ScrollToBottom from "react-scroll-to-bottom"
import { faPaperPlaneAlt } from "@fortawesome/pro-regular-svg-icons"
import { faSparkles } from "@fortawesome/pro-solid-svg-icons"
import { useThreadsApi } from "./question-answering/hooks/useThreadsApi"
import { useThread } from "./question-answering/hooks/useThread"
import Select from "react-select"
import colors from "tailwindcss/colors"
import { DateTime } from "luxon"
import { BetaLabel } from "./common/BetaLabel"
import { getCacheValue, setCacheValue } from "../utils/localStorageCache"

const LAST_QUESTION_CACHE_KEY = "global-last-question"

export function SearchModal(props: { isOpen: boolean; onClose: () => void }) {
    const navigate = useNavigate()

    function navigateTo(url: string, e?: React.MouseEvent<HTMLAnchorElement>) {
        if (e) e.preventDefault() // Disallow default <a> href navigation

        // Navigate to a search result's page
        if (e && (e.ctrlKey || e.metaKey)) {
            window.open(url, "_blank")
        } else {
            props.onClose() // Only close the modal if the user is not opening in a new tab
            navigate(url)
        }
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} noCloseButton>
            <Search navigateTo={navigateTo} />
        </Modal>
    )
}

function Search(props: {
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
}) {
    const [askGlyphicThreadId, setAskGlyphicThreadId] = useState<string | null>(
        null
    )
    const [askGlyphicMessages, setAskGlyphicMessages] = useState<Message[]>([])
    const [askGlyphicError, setAskGlyphicError] = useState<string | null>(null)
    const [askGlyphicSinceDate, setAskGlyphicSinceDate] = useState<string>()
    const { createThread: createAskGlyphicThread } = useThreadsApi()

    const {
        sendMessage: sendAskGlyphicMessage,
        rerunThread: rerunAskGlyphicThread,
        isLoading: receivingAskGlyphicResponse,
    } = useThread({
        onError: (error) => {
            setAskGlyphicError("Failed to generate a response")
            setAskGlyphicMessages((prev) => prev.slice(0, -1))
            console.error(error)
        },
        onStreamUpdate: (delta) => {
            setAskGlyphicMessages((prev) => {
                const lastMessage = prev[prev.length - 1]
                if (lastMessage && lastMessage.role === "ai") {
                    return [
                        ...prev.slice(0, -1),
                        {
                            ...lastMessage,
                            content: lastMessage.content + delta,
                        },
                    ]
                } else {
                    return prev
                }
            })
        },
    })

    useEffect(() => {
        if (receivingAskGlyphicResponse) {
            setAskGlyphicError(null)
            setAskGlyphicMessages((prev) => [
                ...prev,
                {
                    role: "ai",
                    content: "",
                },
            ])
        }
    }, [receivingAskGlyphicResponse])

    const submitMessage = useCallback(
        async (threadId: string, message: string) => {
            setAskGlyphicMessages((prev) => [
                ...prev,
                {
                    role: "human",
                    content: message,
                },
            ])
            await sendAskGlyphicMessage(threadId, message)
        },
        [sendAskGlyphicMessage]
    )

    const startAskGlyphicThread = async (
        initialMessage: string,
        sinceDate?: DateTime
    ) => {
        const thread = await createAskGlyphicThread({
            since: sinceDate,
        })
        setAskGlyphicThread(thread)
        submitMessage(thread.id, initialMessage)
        setCacheValue(LAST_QUESTION_CACHE_KEY, initialMessage)
        getHeapInstance()?.track("ask-glyphic-global", {
            initialMessage: initialMessage,
            sinceDate: sinceDate?.toISO(),
        })
    }
    const setAskGlyphicThread = (thread: Thread | null) => {
        setAskGlyphicThreadId(thread?.id || null)
        setAskGlyphicMessages(thread?.messages || [])
        setAskGlyphicError(null)
        setAskGlyphicSinceDate(thread?.since || undefined)
    }

    if (askGlyphicThreadId) {
        return (
            <GlobalAskGlyphicThread
                threadId={askGlyphicThreadId}
                messages={askGlyphicMessages}
                sinceDate={askGlyphicSinceDate}
                receivingResponse={receivingAskGlyphicResponse}
                setThread={setAskGlyphicThread}
                submitMessage={submitMessage}
                rerunThread={rerunAskGlyphicThread}
                error={askGlyphicError}
            />
        )
    }

    return (
        <SearchView
            navigateTo={props.navigateTo}
            setThread={setAskGlyphicThread}
            startThread={startAskGlyphicThread}
        />
    )
}

function SearchView({
    navigateTo,
    startThread,
    setThread,
}: {
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
    startThread: (initialMessage: string, sinceDate?: DateTime) => void
    setThread: (thread: Thread | null) => void
}) {
    const queryClient = useQueryClient()
    const inputRef = useRef<HTMLInputElement>(null)
    const [searchTerm, setSearchTerm] = useState("")
    const [sinceDate, setSinceDate] = useState<DateTime | undefined>()

    // Debounce the search term to avoid making too many requests between keystrokes
    const debounceTimeMs = 200
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeMs)

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "ArrowUp" && !searchTerm.trim()) {
                const lastQuestion = getCacheValue(LAST_QUESTION_CACHE_KEY, "")
                if (lastQuestion) {
                    setSearchTerm(lastQuestion)
                    // Prevent default to avoid cursor moving to start of input
                    e.preventDefault()
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener("keydown", handleKeyDown)
    }, [searchTerm])

    useEffect(() => {
        // Focus the input when the component mounts
        if (inputRef.current) {
            inputRef.current.focus()
        }

        // Invalidate queries, so they are not stale when the component is re-opened
        return () => {
            queryClient.removeQueries({ queryKey: ["search/"] })
        }
    }, [queryClient])

    const { data, isPending } = useQuery<ISearchResult[]>({
        queryKey: ["search/", debouncedSearchTerm, sinceDate],
        queryFn: async () => {
            const results = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/search`,
                {
                    query: debouncedSearchTerm,
                    filters: {
                        since_date: sinceDate,
                    },
                }
            )
            getHeapInstance()?.track("search-query", {
                query: debouncedSearchTerm,
                resultCount: results.data.length,
            })
            return results.data
        },
        // Never refetch queries while component is open. They are cleared on component unmount
        staleTime: Infinity,
    })
    const results = data || []

    const startThreadWithDate = useCallback(
        (initialMessage: string) => {
            startThread(initialMessage, sinceDate)
        },
        [startThread, sinceDate]
    )

    return (
        <div className="flex flex-col w-[800px] max-w-full h-[600px] divide-y-[1px]">
            <span className="px-3 py-8 flex items-center h-12">
                <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
                <input
                    id="search-input"
                    ref={inputRef}
                    type="text"
                    className="p-3 w-full border-none focus:outline-none"
                    placeholder="Search or just ask a question..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value)
                    }}
                    autoComplete="off"
                    autoFocus
                />
                <DateSelect setSinceDate={setSinceDate} />
            </span>
            <div className="px-4 py-6 flex-grow overflow-auto space-y-4">
                {searchTerm.trim() ? (
                    <SearchContent
                        searchTerm={searchTerm}
                        isPending={isPending}
                        results={results}
                        navigateTo={navigateTo}
                        debouncedSearchTerm={debouncedSearchTerm}
                        startThread={startThreadWithDate}
                    />
                ) : (
                    <SearchEmptyState
                        selectThread={setThread}
                        startThread={startThreadWithDate}
                    />
                )}
            </div>
        </div>
    )
}

function DateSelect(props: {
    setSinceDate: (date: DateTime | undefined) => void
}) {
    const dateOptions = [
        { label: "All time", value: undefined },
        {
            label: "Last 7 days",
            value: DateTime.now().minus({ days: 7 }).startOf("day"),
        },
        {
            label: "Last 30 days",
            value: DateTime.now().minus({ days: 30 }).startOf("day"),
        },
        {
            label: "Last 3 months",
            value: DateTime.now().minus({ months: 3 }).startOf("day"),
        },
        {
            label: "Last 6 months",
            value: DateTime.now().minus({ months: 6 }).startOf("day"),
        },
    ]
    const [sinceOption, setSinceOption] = useState<
        (typeof dateOptions)[0] | null
    >(null)

    useEffect(() => {
        props.setSinceDate(sinceOption?.value)
    }, [sinceOption, props])

    return (
        <Select
            options={dateOptions}
            className="w-72 text-sm"
            styles={{
                control: (provided) => ({
                    ...provided,
                    borderColor: colors.gray[200],
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                    "&:hover": {
                        borderColor: colors.gray[300],
                        backgroundColor: colors.gray[50],
                    },
                }),
                menu: (provided: any) => ({
                    ...provided,
                    padding: "0px 4px 0px 4px",
                    borderRadius: "0.5rem",
                }),
                option: (provided: any) => ({
                    ...provided,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "0.5rem",
                    "&:hover": {
                        backgroundColor: colors.gray[100],
                    },
                }),
            }}
            onChange={(option) => {
                if (option?.value === undefined) {
                    setSinceOption(null)
                } else {
                    setSinceOption(option)
                }

                getHeapInstance()?.track("search-modal-date-selected", {
                    option: option?.label,
                })
            }}
            value={sinceOption}
            placeholder="Filter by date"
            defaultValue={undefined}
            formatOptionLabel={(option, { context }) => {
                if (context === "value") {
                    return `Since: ${option.label}`
                }
                return option.label
            }}
            isSearchable={false}
        />
    )
}

function GlobalAskGlyphicThread({
    threadId,
    messages,
    sinceDate,
    receivingResponse,
    setThread,
    submitMessage,
    rerunThread,
    error,
}: {
    threadId: string
    messages: Message[]
    sinceDate?: string
    receivingResponse: boolean
    setThread: (thread: Thread | null) => void
    submitMessage: (threadId: string, message: string) => Promise<void>
    rerunThread: (threadId: string) => Promise<void>
    error: string | null
}) {
    const { sendFeedback } = useFeedbackApi()
    return (
        <div className="flex flex-col w-[800px] max-w-full h-[600px] divide-y-[1px]">
            <GlobalAskGlyphicThreadHeader
                sinceDate={sinceDate}
                setThread={setThread}
            />
            <ScrollToBottom
                className="h-full flex-grow overflow-auto"
                followButtonClassName="hidden"
            >
                <div className="px-4 py-6">
                    <AskGlyphicThread
                        messages={messages}
                        receivingResponse={receivingResponse}
                        submitFeedback={(feedback) =>
                            sendFeedback(threadId, feedback)
                        }
                        sendEmail={sendFollowUpEmail}
                    />
                </div>
            </ScrollToBottom>
            {error && (
                <div className="mt-4 p-3 bg-red-50 flex items-center justify-between">
                    <span className="text-red-600 text-sm">{error}</span>
                    <PriorityButton
                        onClick={() => threadId && rerunThread(threadId)}
                        className="ml-4 text-sm"
                    >
                        Retry
                    </PriorityButton>
                </div>
            )}
            <AskGlyphicTextbox
                threadId={threadId}
                submitMessage={submitMessage}
                disabled={receivingResponse}
            />
        </div>
    )
}

function GlobalAskGlyphicThreadHeader({
    sinceDate,
    setThread,
}: {
    sinceDate?: string
    setThread: (thread: Thread | null) => void
}) {
    return (
        <span className="p-4 flex items-center h-12 justify-between">
            <FontAwesomeIcon
                icon={faChevronLeft}
                className="text-gray-500 cursor-pointer hover:text-gray-700"
                onClick={() => setThread(null)}
            />
            {sinceDate && (
                <span className="text-gray-500 text-sm">{`Using data since ${getSimpleDate(
                    sinceDate
                )}`}</span>
            )}
            <BetaLabel />
        </span>
    )
}

function AskGlyphicTextbox(props: {
    threadId: string
    submitMessage: (threadId: string, message: string) => Promise<void>
    disabled?: boolean
}) {
    const textBoxRef = useRef<HTMLInputElement>(null)

    const submitInputText = () => {
        if (!textBoxRef.current) return
        const message = textBoxRef.current.value || ""
        props.submitMessage(props.threadId, message)
        textBoxRef.current.value = ""
    }

    return (
        <span className="px-4 py-8 flex items-center h-12">
            <input
                ref={textBoxRef}
                onKeyDown={(e) => {
                    if (props.disabled) return
                    if (e.key === "Enter") {
                        e.preventDefault()
                        submitInputText()
                    }
                }}
                type="text"
                className="p-3 w-full border-none focus:outline-none"
                placeholder="Ask a follow-up question..."
                autoFocus
            />
            <PriorityButton onClick={submitInputText} disabled={props.disabled}>
                <FontAwesomeIcon
                    className="text-gray-700"
                    icon={faPaperPlaneAlt}
                />
            </PriorityButton>
        </span>
    )
}

function SearchContent(props: {
    searchTerm: string
    isPending: boolean
    results: ISearchResult[]
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
    debouncedSearchTerm: string
    startThread: (initialMessage: string) => void
}) {
    const [selectedIndex, setSelectedIndex] = useState(-1) // -1 represents AI Search

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "ArrowUp") {
                setSelectedIndex((prevIdx) => Math.max(prevIdx - 1, -1))
            } else if (e.key === "ArrowDown") {
                setSelectedIndex((prevIdx) =>
                    Math.min(prevIdx + 1, props.results.length - 1)
                )
            } else if (e.key === "Enter") {
                if (selectedIndex === -1) {
                    props.startThread(props.searchTerm)
                } else if (
                    selectedIndex >= 0 &&
                    selectedIndex < props.results.length
                ) {
                    props.navigateTo(getResultUrl(props.results[selectedIndex]))
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [selectedIndex, props])

    return (
        <div className="space-y-4">
            <AISearchButton
                searchTerm={props.searchTerm}
                onClick={() => props.startThread(props.searchTerm)}
                selected={selectedIndex === -1}
            />
            <div className="space-y-1">
                <span className="text-gray-600 text-sm">Quick results</span>
                {props.isPending && <LoadingState />}
                {!props.isPending && props.results.length === 0 && (
                    <div className="text-gray-500 text-sm text-center">
                        No quick results found. Try asking Glyphic AI Search.
                    </div>
                )}
                <SearchResultList
                    results={props.results}
                    navigateTo={props.navigateTo}
                    searchTerm={props.debouncedSearchTerm}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                />
            </div>
        </div>
    )
}

function AISearchButton(props: {
    searchTerm: string
    selected: boolean
    onClick?: () => void
}) {
    return (
        <div
            onClick={props.onClick}
            className={clsx(
                "flex items-center justify-between rounded-lg py-3 px-4 text-base",
                "cursor-pointer transition-all duration-300 ease-in-out",
                "hover:bg-gradient-to-r hover:from-[#ffc267] hover:via-[#ffdaa2] hover:to-[#ffffff]",
                "text-gray-800 hover:shadow-lg transform hover:-translate-y-0.5",
                "border border-gray-200",
                props.selected
                    ? "bg-gradient-to-r from-[#ffc267] via-[#ffdaa2] to-[#ffffff] shadow-md ring-2 ring-offset-2 ring-[#ffdaa2] border-hidden"
                    : "bg-transparent"
            )}
        >
            <div className="truncate max-w-xs text-sm bg-white bg-opacity-50 text-gray-800 rounded-full py-1 px-3">
                {props.searchTerm.trim()}
            </div>
            <div className="flex items-center space-x-2">
                <FontAwesomeIcon
                    icon={faSparkles}
                    className="w-6 h-6 text-gray-600 animate-pulse"
                />
                <span className="font-semibold text-lg">AI Search (Beta)</span>
            </div>
        </div>
    )
}

function SearchResultList(props: {
    results: ISearchResult[]
    navigateTo: (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => void
    searchTerm: string
    selectedIndex: number
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
}) {
    const searchTerm = props.searchTerm
    const setSelectedIndex = props.setSelectedIndex

    // Navigate to a search result's page, and track event in heap
    const navigateTo = useCallback(
        (url: string, e?: React.MouseEvent<HTMLAnchorElement>) => {
            getHeapInstance()?.track("search-click", {
                query: searchTerm,
                selectedResultIdx: props.selectedIndex,
                url: url,
            })
            props.navigateTo(url, e)
        },
        [searchTerm, props]
    )

    // Reset selected index when the search term changes
    useEffect(() => {
        setSelectedIndex(-1)
    }, [searchTerm, setSelectedIndex])

    return (
        <div className="space-y-2">
            {props.results.map((result, idx) => {
                const isCall = result.type === SearchResultType.CALL
                const isCompany = result.type === SearchResultType.COMPANY
                const isSelected = idx === props.selectedIndex
                const resultUrl = getResultUrl(result)
                const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) =>
                    navigateTo(resultUrl, e)
                if (isCall) {
                    return (
                        <CallSearchResult
                            key={result.id}
                            result={result as ICallSearchResult}
                            isSelected={isSelected}
                            setIsSelected={() => props.setSelectedIndex(idx)}
                            handleClick={handleClick}
                            href={resultUrl}
                        />
                    )
                }
                if (isCompany)
                    return (
                        <CompanySearchResult
                            key={result.id}
                            result={result as ICompanySearchResult}
                            isSelected={isSelected}
                            setIsSelected={() => props.setSelectedIndex(idx)}
                            handleClick={handleClick}
                            href={resultUrl}
                        />
                    )
                return <></>
            })}
        </div>
    )
}

function LoadingState() {
    const elementHeights = [14, 18, 12, 16, 14, 18, 12, 16, 14, 18, 12]
    return (
        <div className="space-y-4">
            {elementHeights.map((height, idx) => (
                <div
                    key={idx}
                    className={`h-${height} bg-gray-100 rounded animate-pulse`}
                />
            ))}
        </div>
    )
}

function CallSearchResult(props: {
    result: ICallSearchResult
    isSelected: boolean
    setIsSelected: () => void
    handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
}) {
    const result = props.result

    return (
        <SearchResult
            id={result.id}
            heading={result.call_metadata.title || "Untitled Call"}
            subheading={
                result.call_metadata.start_time
                    ? getFormattedDateTime(result.call_metadata.start_time)
                    : ""
            }
            highlights={result.highlight}
            isSelected={props.isSelected}
            setIsSelected={props.setIsSelected}
            handleClick={props.handleClick}
            image={
                <div className="h-10 w-10 rounded-md bg-gray-300 flex justify-center items-center">
                    <RecordingIcon className="h-7 w-7 text-white" />
                </div>
            }
            href={props.href}
        />
    )
}

function CompanySearchResult(props: {
    result: ICompanySearchResult
    isSelected: boolean
    setIsSelected: () => void
    handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
}) {
    const result = props.result
    return (
        <SearchResult
            id={result.id}
            heading={result.name || result.primary_domain}
            subheading={result.primary_domain}
            highlights={result.highlight}
            isSelected={props.isSelected}
            setIsSelected={props.setIsSelected}
            handleClick={props.handleClick}
            image={<CompanyLogo image_url={result.image_url} />}
            href={props.href}
        />
    )
}

function SearchResult(props: {
    id: string
    image: any
    heading: string
    subheading: string
    highlights: ISearchResultHighlight[]
    isSelected: boolean
    setIsSelected: () => void
    handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    href: string
}) {
    return (
        <a
            key={props.id}
            className={clsx(
                "flex space-x-2 rounded-md px-3 py-2 text-base hover:cursor-pointer",
                props.isSelected && "bg-gray-100"
            )}
            onClick={props.handleClick}
            onMouseOver={props.setIsSelected}
            href={props.href}
        >
            <div className="pt-1">{props.image}</div>
            <div className="w-full space-y-1">
                <div className="flex justify-between">
                    <span className="space-x-2">
                        <span className="font-semibold">{props.heading}</span>
                    </span>
                    <span className="text-gray-400">{props.subheading}</span>
                </div>
                <div className="text-gray-700">
                    <span>{props.highlights[0].field_name}: </span>
                    {props.highlights[0].texts.map(
                        (
                            segment: ISearchResultHighlightElement,
                            index: number
                        ) => (
                            <span
                                key={index}
                                className={clsx(
                                    segment.type === "hit" && "font-bold",
                                    segment.type === "text" && "text-gray-400"
                                )}
                            >
                                {segment.value}
                            </span>
                        )
                    )}
                </div>
            </div>
        </a>
    )
}

function getResultUrl(result: any): string {
    if (result.type === SearchResultType.CALL) {
        return `/calls/${result.id}`
    }
    if (result.type === SearchResultType.COMPANY) {
        return `/companies/${result.primary_domain}`
    }
    return ""
}
