import { EditableCell } from "./EditableCell"
import { BaseEditableCellProps } from "./types"

type EditableAmountCellProps = BaseEditableCellProps<number>

export function EditableAmountCell({
    value,
    onChange,
    isEditable,
}: EditableAmountCellProps) {
    return (
        <EditableCell
            value={value}
            onChange={onChange}
            isEditable={isEditable}
            renderDisplay={(val: number | null) => (
                <div className="w-24">{val ? val.toLocaleString() : "--"}</div>
            )}
            renderEdit={(val, setVal, onBlur) => (
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={val?.toString() || ""}
                    onChange={(e) => {
                        const newVal = e.target.value
                        if (newVal === "" || !isNaN(Number(newVal))) {
                            setVal(newVal === "" ? 0 : Number(newVal))
                        }
                    }}
                    onBlur={onBlur}
                    onKeyDown={(e) => e.key === "Enter" && onBlur()}
                    className="w-24 border-gray-400 rounded-lg"
                    autoFocus
                />
            )}
            validate={(val) => val === null || !isNaN(val)}
        />
    )
}
