import { ICrmDealStage } from "../../crm/types/Crm"
import { EditableCell } from "./EditableCell"
import { BaseEditableCellProps } from "./types"

interface EditableDealStageCellProps
    extends BaseEditableCellProps<ICrmDealStage> {
    dealStages: ICrmDealStage[]
}

export function EditableDealStageCell({
    value,
    dealStages,
    onChange,
    isEditable,
}: EditableDealStageCellProps) {
    return (
        <EditableCell
            value={value}
            onChange={onChange}
            isEditable={isEditable}
            renderDisplay={(val: ICrmDealStage | null) => (
                <div className="w-32 truncate ellipsis">
                    {val?.name || "--"}
                </div>
            )}
            renderEdit={(val, setVal, onBlur) => (
                <select
                    value={val?.crm_id || ""}
                    onChange={(e) => {
                        const newStage = dealStages.find(
                            (stage) => stage.crm_id === e.target.value
                        )
                        if (newStage) setVal(newStage)
                    }}
                    onBlur={onBlur}
                    onKeyDown={(e) => e.key === "Enter" && onBlur()}
                    className="w-32 border-gray-400 rounded-lg"
                    autoFocus
                >
                    {dealStages.map((stage) => (
                        <option key={stage.crm_id} value={stage.crm_id}>
                            {stage.name}
                        </option>
                    ))}
                </select>
            )}
        />
    )
}
