import axios from "axios"
import { ICRMDeal, ICrmDealUpdate } from "../../types/Deal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../../components/common/Notifcations"
import { getHeapInstance } from "../../utils/heap"

export function useUpdateDeal(queryKey: any[] | readonly any[]) {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()

    const updateDealData = (updatedDeal: ICRMDeal) => {
        queryClient.setQueryData(
            queryKey,
            (oldDealListData: ICRMDeal[] | undefined) => {
                if (!oldDealListData) {
                    return [updatedDeal]
                }
                return oldDealListData.map((deal) => {
                    if (deal.id === updatedDeal.id) {
                        return { ...deal, ...updatedDeal }
                    }
                    return deal
                })
            }
        )
    }

    return useMutation({
        mutationFn: async (dealUpdate: ICrmDealUpdate) => {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/deals/crm/${dealUpdate.id}`,
                dealUpdate
            )
            getHeapInstance()?.track("deal-value-updated", {
                dealId: dealUpdate.id,
            })
            return response.data as ICRMDeal
        },
        onSuccess: (updatedDeal: ICRMDeal) => {
            updateDealData(updatedDeal)

            addNotification(
                "Deal updated",
                `Deal ${updatedDeal.name} updated successfully`,
                NotificationType.Success
            )
        },
        onError: (error) => {
            let errorMessage = "An error occurred while updating the deal."
            if (axios.isAxiosError(error)) {
                errorMessage =
                    error.response?.data?.detail?.message || errorMessage
            }
            addNotification(
                "Failed to update deal",
                errorMessage,
                NotificationType.Error
            )
            console.error("Error updating deal:", error)
        },
    })
}
