import { useQuery } from "@tanstack/react-query"
import { useState, useEffect } from "react"
import { IMultiSelectOption, MultiSelectFilter } from "../MultiSelectFilter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTag } from "@fortawesome/pro-solid-svg-icons"
import { queries } from "../../api/queries"
import { stringToColour } from "../../utils/stringToColour"

export function TagFilter(props: {
    onTagChange: (tags: string[]) => void
    selectedTags: string[]
    defaultMenuIsOpen?: boolean
}) {
    const [selectedOptions, setSelectedOptions] = useState<
        IMultiSelectOption[]
    >([])

    const { data: tags, isPending } = useQuery(queries.callTags.list())

    useEffect(() => {
        if (tags) {
            const initialSelectedOptions = tags
                .filter((tag) => props.selectedTags.includes(tag.name))
                .map((tag) => ({ value: tag.name, label: tag.name }))
            setSelectedOptions(initialSelectedOptions)
        }
    }, [tags, props.selectedTags])

    const handleTagChange = (newValue: IMultiSelectOption[]) => {
        setSelectedOptions(newValue)
        props.onTagChange(newValue.map((option) => option.value))
    }

    const options: IMultiSelectOption[] =
        tags?.map((tag) => ({
            value: tag.name,
            label: tag.name,
            color: stringToColour(tag.name)[400],
        })) || []

    return (
        <MultiSelectFilter
            title="Tags"
            icon={<FontAwesomeIcon icon={faTag} className="text-gray-500" />}
            options={options}
            value={selectedOptions}
            onApply={handleTagChange}
            isLoading={isPending}
            isClearable={false}
            defaultMenuIsOpen={props.defaultMenuIsOpen ?? true}
        />
    )
}
